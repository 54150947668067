import React, { useEffect, useRef, useState } from 'react';
import back from '../images/back-left.svg';
import { Link } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import axios from 'axios';
import constants from '../constants/constants';
import { useStoreState } from 'easy-peasy';
import { Toast } from 'primereact/toast';

function BookingHistory() {
    const toast = useRef(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userDetail = useStoreState((state) => state.tabModel.userDetail);

    useEffect(() => {
        setIsLoading(true);
        getBookingHistory();
    }, []);

    const getBookingHistory = () => {
        const payload={
            userId: userDetail?.userId,
        }
        axios
            .post(constants.URL.LIST_USER_BOOKED,payload )
            .then((resp) => {
                setData(resp.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleCancelBooking = (item) => {
        const payload = {
            bookingId: item?.bookingId,
            playerCount: item?.clubSlot?.playerCount,
            slotId: item?.slotId,
            bookingType: item?.clubSlot?.slotStatus,
            createdDate: item?.clubSlot?.createdDate,
            slotStartTimeStamp:item?.clubSlot?.slotStartTimeStamp,
            cancelledBy: userDetail?.userFname
        }
        // console.log(payload);
        axios
            .post(constants.URL.DELETE_USER_BOOKING, payload)
            .then((resp) => {
                if(resp.data.Status == "Failed"){
                    toast.current.show({ severity: "error", summary: "Failure", detail: resp?.data?.Message });
                } else {
                toast.current.show({ severity: "success", summary: "Success", detail: "" });
                getBookingHistory();
                }
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className='os-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/user-home' className='no-underline flex w-6'>
                    <img src={back} className='mr-2' alt='arrow-back' />
                    <h2 className='bk-heading'> Booking History</h2>
                </Link>
                <SidebarMenu />
            </div>
            <div className='bk-inner'>
                <div className=''>
                    {data?.sort((a, b) => {
                        const startTimeA = new Date(a?.clubSlot?.slotStartTimeStamp);
                        const startTimeB = new Date(b?.clubSlot?.slotStartTimeStamp);
                        return startTimeB - startTimeA;
                    })?.map((item) => {
                        const originalDate = new Date(item?.clubSlot?.slotStartTimeStamp);
                        const options = { month: "short", day: "numeric" };
                        
                        const formattedDate = originalDate.toLocaleString("en-US", options);
                        const startTime = originalDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                        return(
                            <div className='card os-card-inner'>
                                <div className='flex align-items-center'>
                                    <div className='bk-title col-4'>Booking Name</div>
                                    <div className='bk-value'>{((item?.bookingName === "SuperAdmin") || (item?.bookingName === "Super Admin")) ? "Priyadarshi" : item?.bookingName}</div>
                                </div>
                                <div className='flex align-items-center mt-2'>
                                    <div className='bk-title col-4'>Booking ID</div>
                                    <div className='bk-value'>{item?.bookingId}</div>
                                </div>
                                <div className='flex align-items-center mt-2'>
                                    <div className='bk-title col-4'>Players</div>
                                    <div className='bk-value'>{item?.playersName?.split(',')?.filter(name => name.trim() !== 'undefined')?.join(', ')}</div>
                                </div>
                                {item?.clubSlot == null ? null :
                                <div className='flex align-items-center mt-2'>
                                    <div className='bk-title col-4'>Starting Tee</div>
                                    <div className='bk-value'>{item?.clubSlot?.teeTime}</div>
                                </div>
                    }
                                <div className='flex align-items-center'>
                                    <div className='bk-title col-4'>Date & Time</div>
                                    <div className='bk-value w-5'>{item?.clubSlot == null ? null : formattedDate+','+startTime}</div>
                                    {item?.clubSlot == null ?
                                    <div className='bh-checkin-btn bg-teal-500'>Completed</div>
                                    : item?.approvalStatus == "Booking Cancelled" ? 
                                    <div className='bh-checkin-btn bg-orange-500'>Cancelled By Admin</div>
                                    : item?.approvalStatus == "Cancelled By User" ? 
                                    <div className='bh-checkin-btn bg-orange-500'>Cancelled</div>
                                    : item?.approvalStatus == "Approved" ? 
                                    <div className='flex gap-2'><div className='bh-checkin-btn'>Booked</div><div className='bh-checkin-btn bg-red-500 cursor-pointer' onClick={()=> handleCancelBooking(item)}>Cancel</div></div>
                                    : item?.approvalStatus == "Rejected" ? 
                                    <div className='bh-checkin-btn bg-purple-300'>Rejected</div>
                                    :
                                    <div className='flex gap-2'><div className='bh-checkin-btn'>Booked</div><div className='bh-checkin-btn bg-red-500 cursor-pointer' onClick={()=> handleCancelBooking(item)}>Cancel</div></div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BookingHistory;