import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SidebarMenu from '../components/SidebarMenu';
import back from '../images/icon_arrow_left.svg';
import axios from 'axios';
import constants from '../constants/constants';
import { useStoreState } from 'easy-peasy';
import 'primeicons/primeicons.css';
import calenderIcon from '../images/icon_calendar.png';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from "primereact/toast";
import trash from '../images/icon_trash.png';
import edit from '../images/icon _edit 3_.svg';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';

function OpenedSlot() {
    const navigate = useNavigate();
    const toast = useRef(null);
    let today = new Date();
    const [selectedDate, setSelectedDate] = useState(today);

    const [data, setData] = useState(null);
    const [date, setDate] = useState(today);
    const [records, setRecords] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userDetail = useStoreState((state) => state.tabModel.userDetail);

    useEffect(() => {
        handleBookedSlotData()
    }, [date]);

    function formatFilteredDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day ].join('-');
    }

    useEffect(() => {
        if (date && data && data?.length > 0) {
            let filteredData = data?.filter((item)=> item.slotDate === formatFilteredDate(selectedDate));
            setRecords(filteredData);
        }
    }, [date, data]);

    const handleBookedSlotData = () =>{
        setData([]);
        setIsLoading(true);
        let today = new Date(date);
        let year = today.getFullYear();
        let month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        let day = String(today.getDate()).padStart(2, '0');

        let slotDate = `${year}-${month}-${day}`;
        const payload = {
            slotDate: slotDate
        }
        axios
            .post(constants.URL.LIST_BOOKED_SLOT, payload)
            .then((resp) => {
                setData(resp.data);
            })
            .catch((e) => console.error(e))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const reject = () => {
        toast.current.show({
            severity: "warn",
            summary: "Rejected",
            detail: "You have rejected",
            life: 3000
        });
    };

    const confirm1 = (e, item) => {
        confirmDialog({
            target: e.currentTarget,
            message: "Are you sure you want to Delete the slot?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => handleDelete(item),
            reject
        });
    };

    const handleDelete = (item) => {
        const payload = {
            bookingId: item?.bookingId,
            playerCount: item?.playerCount,
            slotId: item?.slotId,
            bookingType: item?.clubSlot?.slotStatus,
            cancelledBy: userDetail?.userFname
        }
        setIsLoading(true);
        axios
            .post(constants.URL.DELETE_BOOKING, payload)
            .then((resp) => {
                toast.current.show({ severity: "success", summary: "Success", detail: "Deleted Successfully" });
                handleBookedSlotData()
            })
            .catch((e) => {
                console.error(e)
                setIsLoading(false);
            })
            .finally(() => {
            });
    }

    const handleEdit = (item) => {
        navigate("/booking-edit-page", { state: { item } });
    };

    return (
        <div className='os-admin-wrapper'>
            <Toast ref={toast} />
            <div className='flex justify-content-between align-items-center ds-admin-topbar'>
                <Link to='/dashboard' className='no-underline w-6'>
                    <img src={back} />
                </Link>
                <SidebarMenu />
            </div>
            <div className='os-inner'>
                <ConfirmDialog />
                <h2 className='os-heading mb-3 flex justify-content-between align-items-center'>Booked Slots
                <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon style={{width: "130px"}} dateFormat='dd-mm-yy' />
                </h2>
                {isLoading ? <small className='my-3 pb-4'>Loading...</small> : (data?.length === 0 && !isLoading) ? <small className='my-3 pb-4'>No Data found for selected Date</small> : null}
                {data && data?.length > 0 ?
                                <div className='mb-3'>
                                    <div className='card flex justify-content-between align-items-center os-card-title'>
                                        <div className='w-2'>Name</div>
                                        <div className='w-2'>Players</div>
                                        <div className='w-4 text-center'>Starting Tee</div>
                                        <div className='w-4'>Time Slot</div>
                                        <div className='w-3'></div>
                                    </div>
                                    {data && data?.map((item) => {
                                        // // console.log(item);
                                        const playersString = item?.player;
                                       // const playersArray = playersString.split(",");
                                        const playerCount = item?.playerCount;

                                        var start = item?.clubSlot?.slotStartTimeStamp;
                                        var end = item?.clubSlot?.slotEndTimeStamp;

                                        const startTime = new Date(start);
                                        const endTime = new Date(end);

                                        const startHour = startTime.getHours();
                                        const startMinute = startTime.getMinutes();
                                        const endHour = endTime.getHours();
                                        const endMinute = endTime.getMinutes();

                                        // Format the start and end times
                                        const startPeriod = startHour >= 12 ? "pm" : "am";
                                        const endPeriod = endHour >= 12 ? "pm" : "am";

                                        const startHour12 = startHour % 12 || 12;
                                        const endHour12 = endHour % 12 || 12;

                                        const startTimeFormatted = `${startHour12}:${startMinute < 10 ? "0" : ""}${startMinute}${startPeriod}`;
                                        const endTimeFormatted = `${endHour12}:${endMinute < 10 ? "0" : ""}${endMinute}${endPeriod}`;

                                        // Combine the start and end times
                                        const timeRangeFormatted = `${startTimeFormatted}`;
                                        // // console.log(timeRangeFormatted);  
                                        return (
                                            <div className='card flex justify-content-between align-items-center os-card-inner'>
                                                <div className='w-3'>{item?.clubUser?.userFname === "Super Admin" ? "Priyadarshi" : item?.clubUser?.userFname}</div>
                                                <div className='w-2'>{playerCount}</div>
                                                <div className='w-2 text-center'>{item?.clubSlot?.teeTime}</div>
                                                <div className='w-4 pl-4'>{timeRangeFormatted}</div>
                                                <div className='w-2 flex align-items-center'>
                                                <div className={(item.bookingStatus != "Booking Cancelled" && item.bookingStatus != "Cancelled By User") ? "mr-3" : "hidden mr-3"}>
                                                            <img src={edit} onClick={() => handleEdit(item)} alt='img' width="12px" height="13.3px" />
                                                        </div>
                                                        {userDetail?.roles[0]?.roleId === 1 ?
                                                            <div className={(item.bookingStatus != "Booking Cancelled" && item.bookingStatus != "Cancelled By User") ? "mr-2" : "hidden mr-2"}>
                                                                <img src={trash} onClick={(e) => confirm1(e, item)} alt='img' width="12px" height="13.3px" />
                                                            </div>
                                                            : null}
                                                    </div>
                                                
                                                {(item.bookingStatus != "Booking Cancelled" && item.bookingStatus != "Cancelled By User") ?
                                                    <div className='w-2 flex align-items-center'>
                                                        <div className={item.updatedDate ? "" : "hidden"}>
                                                            <Tag severity="info" className='text-center' value={item.updatedDate ? "Edited By "+ item?.updatedBy : "CheckIn"}></Tag>
                                                        </div>
                                                    </div> :
                                                    <div className='bg-red-500 os-checkin-btn w-2'>{item?.cancelledBy ? "Cancelled By " + item?.cancelledBy : "Cancelled"}</div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                : null}
            </div>
        </div>
    )
}

export default OpenedSlot;